<template>
  <div id="user">
    <div v-if="this.found">

      <p style="font-size: 20px;">{{ user.name }}</p>

      <p class="odd">{{ user.address + ", " + user.postcode }}</p>

      <p>{{ user.mobile }}</p>

      <p class="odd">
        Emergency Contact:
        <br />
        {{ user.emergancy_contact?.name + ", " + user.emergancy_contact?.mobile }}
      </p>
      <br /><br />

      <section v-if="this.display === 'regular'">
        <p style="margin-bottom: 0px;">Active Credits:</p>
        <div id="credits">
          <i class="fa fa-minus" @click="changeCredit(-1)"></i>
          {{ credits }}
          <i class="fa fa-plus" @click="changeCredit(1)"></i>
        </div><br />

        <p v-if="this.subscription === true" id="subscription">
          Subscription:
          <span style='color: rgb(1, 179, 1)'>Active</span>
          <button @click="cancelSubscription">Cancel</button>
        </p>
        <p v-else id="subscription">
          Subscription:
          <span style='color: #ccc'>None</span>
          <button @click="renewSubscription">Activate</button>
        </p>
      </section>

      <section v-if="this.display === 'pt'">
        <p v-if="this.ptVerified === true" id="subscription">
          Profile:
          <span style='color: rgb(1, 179, 1)'>Verified</span>
          <span> </span>
        </p>
        <p v-else id="subscription">
          Profile:
          <span style='color: rgb(255, 36, 36)'>Not Verified</span>
          <button @click="verifyPTProfile">Verify</button>
        </p>
      </section>

      <div v-if="isPT" id="accountTypeSelector">
        <span @click="() => this.display = 'regular'" :class="this.display === 'regular' && 'active'">Regular</span>
        <span @click="() => this.display = 'pt'" :class="this.display === 'pt' && 'active'">PT Profile</span>
      </div>
    </div>

    <div v-else>
      <h1>User Not Found</h1>
    </div>
  </div>

  <div id="tint" @click="close"></div>
</template>

<script>
import {
  findUser,
  findPT,
  addCredit,
  cancelSubscription,
  activateSubscription,
  verifyPT
} from '@/app/routes';

export default {
  name: 'UserModal',
  props: {
    id: String,
    email: String,
    close: Function
  },
  data() {
    return {
      user: {
        name: '',
        address: '',
        postcode: '',
        mobile: '',
        emergancy_contact: {
          name: '',
          mobile: '',
        }
      },
      ptProfile: {
        pod_credit: 0,
      },
      credits: 0,
      isPT: false,
      found: true,
      subscription: false,
      display: 'regular',
      ptVerified: false
    }
  },
  methods: {
    async renderUser() {
      const user = await findUser(this.email);
      console.log(user)
      if (!user) {
        this.found = false;
        return;
      } else this.user = user;
      if (user.subscription > 0) {
        this.subscription = true;
      } else this.subscription = false;
      const pt = await findPT(this.email);
      if (pt) {
        this.ptProfile = pt;
        this.isPT = true;
        if (pt.verified) {
          this.ptVerified = true;
        } else this.ptVerified = false;
        this.credits = pt.credits.reduce((total, credits) => total + (credits.amount - credits.used), 0);
      } else {
        this.credits = user.credits.reduce((total, credits) => total + (credits.amount - credits.used), 0);

      }
    },
    async changeCredit(amount) {
      const response = await addCredit(this.user._id, amount);
      if (response) {
        this.credits += amount;
      }
    },
    async cancelSubscription() {
      const response = await cancelSubscription(this.user._id);
      if (response) {
        this.subscription = false;
      }
    },
    async renewSubscription() {
      const response = await activateSubscription(this.user._id);
      if (response) {
        this.subscription = true;
      }
    },
    async verifyPTProfile() {
      const response = await verifyPT(this.ptProfile._id);
      if (response) {
        this.ptVerified = true;
      }
    }
  },
  mounted() {
    console.log(this.id);
    console.log(this.email);
    this.renderUser();
  },
}
</script>

<style scoped lang="scss">
#user {
  position: fixed;
  height: 500px;
  width: 500px;
  top: calc(50% - 300px);
  right: calc(50% - 295px);
  display: block;
  z-index: 2;
  border-radius: 5px;
  background: rgb(97, 105, 107);
  padding: 20px;
  box-sizing: border-box;
  text-align: left;

  p {
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 30px;
    font-size: 18px;
  }

  .odd {
    color: rgb(204, 204, 204);
  }

  table {
    width: 100%;

    tr {
      display: flex;
      justify-content: space-around;
      align-items: center;

      th {
        font-size: 16px;
        font-weight: bold;
      }

      td {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 15%;
      }
    }
  }

  #credits {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;

    i {
      font-size: 24px;
      cursor: pointer;
      transition: 0.1s;
      margin: 0 20px;

      &:hover {
        color: #ccc;
      }
    }
  }

  #subscription {
    width: 60%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 40px auto;
    font-size: 16px;
    font-weight: bold;

    button {
      background: none;
      border: none;
      color: #ccc;
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
      transition: 0.1s;

      &:hover {
        color: #fff;
      }
    }
  }

  #accountTypeSelector {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    span {
      cursor: pointer;
      color: #fff;
      font-size: 18px;
      width: 50%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.1s;
      border-radius: 0 0 0 5px;

      &:hover {
        color: #ccc;
      }

      &:last-child {
        border-radius: 0 0 5px 0;
      }
    }

    .active {
      background-color: #EFAB7D;
    }
  }
}

#tint {
  position: fixed;
  top: 0;
  left: 90px;
  width: calc(100% - 90px);
  height: 100%;
  background-color: #000;
  opacity: 0.6;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 600px) {
  #user {
    position: fixed;
    height: 500px;
    width: 90%;
    top: calc(50% - 300px);
    right: 5%;
    display: block;
    z-index: 2;
    border-radius: 5px;
    background: rgb(97, 105, 107);
    padding: 20px;
    box-sizing: border-box;
    text-align: left;
  }

  #tint {
    left: 0;
    width: 100%;
  }
}
</style>